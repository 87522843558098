export default defineNuxtRouteMiddleware(async (to) => {
  const metaAuth = to.meta?.auth ?? true;
  const requiredPermissions: string[] = (to.meta?.permissions as string[]) ?? [];
  const { $localePath: localePath, $pinia } = useNuxtApp();
  const authStore = useAuthStore($pinia);

  if (metaAuth === false) {
    return;
  }

  if (authStore.accessToken !== null && !authStore.isLoggedIn) {
    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    await authStore.getSession();
  }

  // This makes sure that the user is logged in and is from MP staff team.
  if (authStore.isLoggedIn && authStore.data?.is_mp_staff) {
    if(!requiredPermissions.every((permission) => authStore.hasPermission(permission))) {
      throw showError({
        statusCode: 403,
        statusMessage: "You do not have permission to access this page."
      });
    }
    return;
  }

  return navigateTo(localePath({ name: "login", query: { next: to.path } }));
});
