<template>
  <div
    :class="['hamburger', isMenuOpen ? 'is-open' : '', ' d-flex cursor-pointer']"
    @click="isMenuOpen = !isMenuOpen"
  >
    <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <line x1="0" y1="11" x2="32" y2="11" stroke-width="3" />
        <line x1="0" y1="21" x2="32" y2="21" stroke-width="3" />
      </g>
      <g>
        <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
        <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
      </g>
    </svg>
  </div>
  <v-dialog
    v-model="isMenuOpen"
    content-class="popup popup--menu aScroll"
  >
    <nuxt-link
      class="popup__close cursor-pointer"
      @click="isMenuOpen = !isMenuOpen"
    >
      <v-icon icon="ct:cross" size="x-small" />
    </nuxt-link>
    <v-card>
      <v-card-title class="font-weight-regular">
        <div class="app__title app__title--h4">
          {{ authStore.data?.name }}
        </div>
        <div class="app__label color--grayTone4 mt-1">
          {{ authStore.data?.email }}
        </div>
        <div :class="['themeSwitch', themeSwitch ? 'is-dirty' : '', 'text-center cursor-pointer']">
          <div
            class="themeSwitch__item flex-center"
            @click="toggleTheme"
          >
            Light
          </div>
          <div
            class="themeSwitch__item flex-center"
            @click="toggleTheme"
          >
            Dark
          </div>
          <div class="themeSwitch__slider" />
        </div>
      </v-card-title>
      <v-card-text>
        <div class="app__divider mb-2" />
        <v-list>
          <v-list-item
            v-for="(item, index) in mobileMenuItems"
            :key="`item-${index}`"
            :prepend-icon="item.icon"
            :title="item.title"
            :to="localePath({'name': item.linkPath})"
            @click="isMenuOpen = !isMenuOpen"
          />
        </v-list>
        <div class="app__divider my-2" />
        <v-list>
          <v-list-item
            :prepend-icon="'ct:user-edit'"
            :title="t('common.edit_account')"
            :to="localePath({'name': 'profile'})"
            @click="isMenuOpen = !isMenuOpen"
          />
          <v-list-item
            :prepend-icon="'ct:logout'"
            :title="t('form.login.log_out')"
            @click="authStore.logout('login')"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useTheme } from "vuetify";
import { useLocalStorage } from "@vueuse/core";
import {mdiInvoiceTextMultipleOutline} from "@mdi/js";
const authStore = useAuthStore();
const localePath = useLocalePath();
const { t } = useI18n();
const isMenuOpen = ref<boolean>(false);
const themeSwitch = ref<boolean>(false);

type MobileMenuItem = {
  title: string
  icon: string
  linkPath?: string
  permissions?: string[]
}

const mobileMenuItems = computed<MobileMenuItem[]>(() => {
  const items = [
    { title: t("main_menu.buyout_list"), icon: "ct:dashboard", linkPath: "cashtec", permissions: ["can_view_buyouts"] },
    { title: t("main_menu.settings_bonus"), icon: "ct:gift", linkPath: "cashtec-setup-bonus", permissions: ["can_view_bonuses"] },
    { title: t("main_menu.blacklist"), icon: "ct:lock-alt", linkPath: "cashtec-blacklist", permissions: ["can_view_blacklist"] },
    { title: t("main_menu.logs"), icon: "ct:pencil", linkPath: "cashtec-logs", permissions: ["can_view_buyout_logs"] },
    { title: t("main_menu.payments"), icon: "ct:cash", linkPath: "cashtec-payments", permissions: ["can_view_payments"] },
    { title: t("main_menu.chats"), icon: "ct:chat", linkPath: "cashtec-chats", permissions: ["can_view_chats"] },
    { title: t("main_menu.export"), icon: "ct:cloud", linkPath: "cashtec-export", permissions: ["can_view_exports"] },
    { title: t("main_menu.tools"), icon: "ct:network", linkPath: "cashtec-tools", permissions: ["can_view_tools"] },
    { title: t("main_menu.business_cases"), icon: "ct:basket", linkPath: "upgrade-business-cases", permissions: ["can_view_business_cases"] },
    { title: t("main_menu.business_case_issues"), icon: "up:triangle-exclamation", linkPath: "upgrade-business-case-issues", permissions: ["can_view_business_case_issues"] },
    { title: t("main_menu.invoices"), icon: mdiInvoiceTextMultipleOutline, linkPath: "upgrade-business-case-invoices", permissions: ["can_view_business_case_invoices"] },
    { title: t("main_menu.program_master_devices"), icon: "ct:phone", linkPath: "upgrade-program-master-devices", permissions: ["can_view_leasing_products"] },
    { title: t("main_menu.installments_variants"), icon: "ct:chart-line", linkPath: "upgrade-installments-variants", permissions: ["can_view_leasing_curves"] }
  ];
  return items.filter(item => (item?.permissions ?? []).every(perm => authStore.hasPermission(perm)));
});

const theme = useTheme();
const preferredTheme = useLocalStorage("preferredTheme", "light");
function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}

onMounted(() => {
  themeSwitch.value = theme.global.current.value.dark;
});

watch(() => theme.global.name.value, () => {
  themeSwitch.value = theme.global.current.value.dark;
  preferredTheme.value = theme.global.name.value;
});
</script>
