import { defineStore, acceptHMRUpdate } from "pinia";
import {useLocalStorage} from "@vueuse/core";

export const useStoreStore = defineStore("storeStore", () => {

  const { $api } = useNuxtApp();


  type Store = Awaited<ReturnType<typeof $api.store.availableMpStores>>["data"]["value"][number];
  type CashDesk = Awaited<ReturnType<typeof $api.store.availableCashDesk>>["data"]["value"][number];

  const store = ref<Store | null>(null);
  const cashDesk = ref<CashDesk | null>(null);

  const localStore = useLocalStorage<Store | null>("store", null);
  const localCashDesk = useLocalStorage<CashDesk | null>("cashDesk", null);

  const areMPStoresLoading = ref<boolean>(true);
  const mpStores = ref<Store[]>([]);
  const areCashDeskLoading = ref<boolean>(true);
  const cashDesks = ref<CashDesk[]>([]);

  const displayCashDesks = computed(() => {
    return cashDesks.value.filter((cashDesk: CashDesk) => store.value ? cashDesk.store_name === store.value?.name : true);
  });

  onMounted(async () => {
    if (typeof localStore.value !== "undefined") {
      store.value = JSON.parse(localStore.value);
    }
    if (localCashDesk.value) {
      cashDesk.value = JSON.parse(localCashDesk.value);
    }
  });

  watch([store, cashDesk], ([newStore, newCashDesk]) => {
    if (newStore) {
      localStore.value = JSON.stringify(newStore);
    }
    if (newCashDesk) {
      localCashDesk.value = JSON.stringify(newCashDesk);
    }
  });

  async function loadMPStores() {
    areMPStoresLoading.value = true;
    const { data } = await $api.store.availableMpStores();
    if (data.value) {
      mpStores.value = data.value;
    }
    areMPStoresLoading.value = false;
  }

  async function loadCashDesk() {
    areCashDeskLoading.value = true;
    const { data } = await $api.store.availableCashDesk();
    if (data.value) {
      cashDesks.value = data.value;
    }
    areCashDeskLoading.value = false;
  }

  return {
    store,
    cashDesk,
    areMPStoresLoading,
    areCashDeskLoading,
    mpStores,
    displayCashDesks,
    loadMPStores,
    loadCashDesk
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreStore, import.meta.hot));
}
