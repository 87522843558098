<template>
  <nuxt-link class="app__logo flex-center" :to="localePath( {name: 'index'})">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 15" class="icon">
      <path
        d="M9.167 14.002a6.824 6.824 0 0 0 2.51-1.591l-2.51-2.504v4.095Zm0-12.842v4.095l2.51-2.504a6.816 6.816 0 0 0-2.51-1.59ZM6.873 14.408a6.885 6.885 0 0 0 2.038-.32V9.65l-.953-.95a1.579 1.579 0 0 1 0-2.236l.953-.95v-4.44a6.885 6.885 0 0 0-2.038-.32v13.654Zm-2.293-.38c.51.176 1.034.292 1.564.344V.788a6.904 6.904 0 0 0-1.564.345v12.894ZM.61 10.412V4.75a6.88 6.88 0 0 0 0 5.663v.001Zm1.674 2.27c.343.304.71.568 1.093.792V1.687c-.384.225-.75.488-1.093.792v10.204Z"
        fill="#ffffff"
      />
    </svg>
  </nuxt-link>
  <menu-left-menu />
  <v-app-bar>
    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>
    <slot>
      <!-- for teleport -->
      <div id="toolbar__after-title" />
    </slot>
    <slot>
      <div class="app__form">
        <div class="formItem--1x6">
          <v-select
            v-model="storeStore.store"
            :label="t('main_menu.store')"
            :items="storeStore.mpStores"
            item-title="name"
            item-value="mp_id"
            return-object
          />
        </div>
        <div class="formItem--1x6">
          <v-select
            v-model="storeStore.cashDesk"
            :label="t('main_menu.cash_desk')"
            :items="storeStore.displayCashDesks"
            item-title="pc_name"
            item-value="ip_address"
            return-object
          />
        </div>
      </div>
    </slot>
    <menu-user-menu />
  </v-app-bar>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const route = useRoute();
const { t } = useI18n();

const storeStore = useStoreStore();

const title = computed<string>(() => {
  if ((route.meta?.title as string)?.includes(".")) {
    return t(route.meta.title as string);
  }
  return (route.meta.title as string) || "Název sekce/stránky";
});


storeStore.loadMPStores();
storeStore.loadCashDesk();

</script>
